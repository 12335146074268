import type { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-full",
  "closeModalOnClickOutside": false,
  "colors": {
    "barBackground": "#000",
    "barButtonBackground": "#fff",
    "barButtonColor": "#000",
    "barButtonHoverBackground": "#333",
    "barButtonHoverColor": "#fff",
    "barTextColor": "#fff",
    "checkboxActiveBackground": "#000",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "#ddd",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#000",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#fff",
    "controlButtonHoverBackground": "#000",
    "controlButtonIconColor": "#000",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#808080",
    "modalBackground": "#fff",
    "modalButtonBackground": "#000",
    "modalButtonColor": "#fff",
    "modalButtonHoverBackground": "#333",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [
      {
        "id": "strictly-necessary",
        "name": {
          "en": "Strictly Necessary",
          "es": "Estrictamente necesarias",
          "fr": "Strictement nécessaires"
        },
        "description": {
          "en": "Needed to make the website work.",
          "es": "Necesarias para que el sitio web funcione.",
          "fr": "Nécessaires pour faire fonctionner le site web."
        },
        "links": {
          "https://policies.google.com/": "Google Tag Manager",
          "https://livestorm.co/privacy-policy": "Livestorm",
          "https://partnerstack.com/policies#privacy-policy": "PartnerStack",
          "https://www.twilio.com/en-us/legal/privacy": "Segment"
        }
      },
      {
        "id": "analytics-internal-use",
        "name": {
          "en": "Analytics (Internal use)",
          "es": "Analíticas (uso interno)",
          "fr": "Analytiques (usage interne)"
        },
        "description": {
          "en": "Basic analytics for internal traffic reporting only.",
          "es": "Analítica básica sólo para informes de tráfico interno.",
          "fr": "Analytiques basiques et anonymes pour mesure interne du trafic."
        },
        "links": {
          "https://amplitude.com/privacy": "Amplitude",
          "https://www.attributionapp.com/privacy/": "Attribution",
          "https://policies.google.com/privacy": "Google Analytics",
          "https://matomo.org/privacy-policy/": "Matomo"
        }
      },
      {
        "id": "live-chat",
        "name": {
          "en": "Live chat",
          "es": "Chat en vivo",
          "fr": "Chat en direct"
        },
        "description": {
          "en": "Used to answer questions to website's visitors and guide them in their discovery of Livestorm.",
          "es": "Usadas para contestar las preguntas de los visitantes del sitio web y guiarlos en su descubrimiento de Livestorm.",
          "fr": "Utilisés pour répondre aux questions des visiteurs du site et les guider dans leur découverte de Livestorm."
        },
        "links": {
          "https://www.intercom.com/legal/privacy": "Intercom"
        }
      }
    ],
    "optional": [
      {
        "id": "advertising",
        "name": {
          "en": "Advertising",
          "es": "Publicitarias",
          "fr": "Publicitaires"
        },
        "description": {
          "en": "Used to display ads to website visitors according to their interactions with Livestorm.",
          "es": "Usadas para enseñar anuncios a los visitantes del sitio web según sus interacciones con Livestorm.",
          "fr": "Utilisés pour afficher des publicités aux visiteurs du site web en fonction de leurs interactions avec Livestorm."
        },
        "links": {
          "https://www.capterra.com/legal/privacy-policy/": "Capterra",
          "https://www.facebook.com/full_data_use_policy": "Facebook",
          "https://policies.google.com/privacy": "Google Ads",
          "https://www.linkedin.com/legal/privacy-policy": "LinkedIn",
          "https://privacy.microsoft.com/en-us/privacystatement/": "Microsoft Services"
        }
      },
      {
        "id": "analytics",
        "name": {
          "en": "Analytics",
          "es": "Analíticas",
          "fr": "Analytiques"
        },
        "description": {
          "en": "Used for traffic analysis",
          "es": "Usadas para el análisis del tráfico.",
          "fr": "Utilisés pour l'analyse du trafic."
        },
        "links": {
          "https://clearbit.com/privacy-policy": "Clearbit",
          "https://customer.io/legal/privacy-policy/": "Customer.io",
          "https://legal.hubspot.com/privacy-policy": "Hubspot",
          "https://www.salesloft.com/privacy-notice/": "SalesLoft",
          "https://www.smartlook.com/privacy": "Smartlook"
        }
      },
      {
        "id": "website-personalization",
        "name": {
          "en": "Website Personalization",
          "es": "Personalización del sitio web",
          "fr": "Personnalisation du site web"
        },
        "description": {
          "en": "Using anonymized data to personalize the website.",
          "es": "Uso de datos anónimos para personalizar el sitio web.",
          "fr": "Utilisation de données anonymes pour personnaliser le site web."
        },
        "links": {
          "https://clearbit.com/privacy-policy": "Clearbit",
          "https://www.mutinyhq.com/privacy": "Mutiny"
        }
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/"
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": true,
  "isCookieIdVisible": true,
  "isCssEnabled": true,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": false,
  "isModalForced": false,
  "locales": [
    "en",
    "es",
    "fr",
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Agree and close",
      "acceptAll": "Accept all",
      "bannerDescription": "With your agreement, we and our partners use cookies in order for our website to work, for analytics and advertising purposes. You can enable or disable optional cookies as desired. See our <a href=\"https://livestorm.co/privacy-policy\">Privacy Policy</a> for more information.",
      "bannerTitle": "We use cookies!",
      "close": "",
      "cookiesFunctional": "Functional cookies",
      "cookiesNecessary": "Necessary cookies",
      "cookiesOptional": "Optional cookies",
      "decline": "Disagree",
      "declineAll": "Decline all",
      "here": "here",
      "iframeBlocked": "To see this, please enable functional cookies",
      "manageCookies": "Learn more",
      "save": "Save",
      "settingsUnsaved": "You have unsaved settings"
    },
    "es": {
      "accept": "Aceptar y cerrar",
      "acceptAll": "Aceptar todo",
      "bannerDescription": "Con su consentimiento, nosotros y nuestros socios utilizamos cookies para que nuestro sitio web funcione, con fines anal&iacute;ticos y publicitarios. Puede activar o desactivar las cookies opcionales como desee. Consulte nuestra <a href=\"http://livestorm.co/privacy-policy\">Pol&iacute;tica de privacidad</a> para obtener m&aacute;s informaci&oacute;n.",
      "bannerTitle": "¡Usamos cookies!",
      "close": "",
      "cookiesFunctional": "Cookies funcionales",
      "cookiesNecessary": "Cookies obligatorias",
      "cookiesOptional": "Cookies opcionales",
      "decline": "Rechazar",
      "declineAll": "Borrar todo",
      "here": "aquí",
      "iframeBlocked": "Para ver esto, por favor habilita las cookies funcionales.",
      "manageCookies": "Más información",
      "save": "Guardar",
      "settingsUnsaved": "Tienes configuraciones no guardadas"
    },
    "fr": {
      "accept": "Accepter et fermer",
      "acceptAll": "Tout accepter",
      "bannerDescription": "Avec votre accord, nos partenaires et nous-m&ecirc;mes utilisons des cookies pour le fonctionnement de notre site web, &agrave; des fins d'analyse et de publicit&eacute;. Vous pouvez activer ou d&eacute;sactiver les cookies optionnels selon votre choix. Voir notre <a href=\"https://livestorm.co/fr/rgpd\">Politique de confidentialit&eacute;</a> pour plus d'informations.",
      "bannerTitle": "Nous utilisons des cookies !",
      "close": "",
      "cookiesFunctional": "Cookies fonctionnels",
      "cookiesNecessary": "Cookies obligatoires",
      "cookiesOptional": "Cookies optionnels",
      "decline": "Refuser",
      "declineAll": "Tout refuser",
      "here": "ici",
      "iframeBlocked": "Pour voir cela, veuillez activer les cookies fonctionnels",
      "manageCookies": "En savoir plus",
      "save": "Sauvegarder",
      "settingsUnsaved": "Vous avez des paramètres non sauvegardés"
    }
  }
} as ModuleOptions