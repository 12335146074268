<template>
  <nav
    ref="$el"
    v-click-outside="onClickOutside"
    class="navigation"
    :class="[
      props.theme,
      {
        'is-opaque': selectedDesktopSectionIndex !== null || mobileMenuIsOpened,
      },
    ]"
  >
    <!-- Logo -->
    <AtomLogo :theme="selectedDesktopSectionIndex !== null || mobileMenuIsOpened ? 'light' : props.theme" />

    <!-- Desktop Items -->
    <UiComponentWrapper class="desktop-items">
      <UiComponentWrapper
        v-for="(
          {
            id,
            title,
            sectionTitle,
            slug,
            items: subItems,
            grid,
            hoverColor: backgroundColor,
            iconColor,
          },
          index
        ) in data.allPrimaryItems"
        :key="id"
        class="item"
        :class="[
          { active: selectedDesktopSectionIndex === index },
          { 'no-subitems': subItems.length === 0 },
        ]"
        :style="{
          '--color': props.theme === 'light' ? backgroundColor && backgroundColor.hex : '#F6F7F9',
        }"
        @mouseenter="
          selectedDesktopSectionIndex = subItems.length === 0 ? null : index
        "
        @mouseleave="selectedDesktopSectionIndex = null"
      >
        <!-- Name -->
        <AtomAnchor
          v-if="subItems.length === 0"
          class="title"
          :to="isPricingPage(slug) ? localePath(toPricingPage) : slug"
          @click="
            track('Navigation bar clicked', {
              category: 'Button',
              label: slug,
              locale,
            })
          "
        >
          {{ title }}
        </AtomAnchor>
        <UiComponentParagraph
          v-else
          class="title"
          :theme="props.theme"
        >
          {{ title }}<UiComponentIcon type="chevron-down" />
        </UiComponentParagraph>

        <!-- Section (if it exists) -->
        <UiComponentWrapper
          v-if="subItems.length > 0"
          class="section"
        >
          <!-- Top -->
          <UiComponentContainer class="top">
            <!-- Main -->
            <UiComponentWrapper
              class="main"
              :class="`grid-${grid.split('/')[0]}`"
            >
              <UiComponentParagraph class="subtitle">
                {{ sectionTitle }}
              </UiComponentParagraph>
              <UiComponentWrapper class="subitems">
                <AtomAnchor
                  v-for="
                    subItem in subItems
                      .filter(({ section }) => section === 'main')
                      .filter(({ isDisplayedOnlyWhenLoggedIn }) => {
                        if (isDisplayedOnlyWhenLoggedIn) {
                          return isLogged;
                        }

                        return true;
                      })
                  "
                  :key="subItem.id"
                  class="subitem"
                  :class="{ 'not-localized': subItem.isEnglishOnly }"
                  :force-no-follow="subItem.isNoFollow"
                  :force-reload="subItem.isEnglishOnly"
                  :to="subItem.slug"
                  @click="
                    track('Navigation bar clicked', {
                      category: 'Button',
                      label: subItem.slug,
                      locale,
                    })
                  "
                >
                  <UiComponentWrapper class="head">
                    <UiComponentIcon
                      v-if="subItem.icon"
                      :style="{ color: props.theme === 'light' ? iconColor.hex : '#A4B5C1' }"
                      :type="subItem.icon.name"
                    />
                  </UiComponentWrapper>
                  <UiComponentWrapper class="body">
                    <UiComponentParagraph>{{ subItem.title }}</UiComponentParagraph>
                    <UiComponentParagraph>{{ subItem.subtitle }}</UiComponentParagraph>
                  </UiComponentWrapper>
                </AtomAnchor>
              </UiComponentWrapper>
              <AtomButton
                v-if="index === 1"
                :to="localePath('industries')"
                type="secondary"
              >
                {{ data.global.allIndustries }}<UiComponentIcon type="arrow-right" />
              </AtomButton>
            </UiComponentWrapper>

            <!-- Aside -->
            <UiComponentWrapper
              class="aside"
              :class="[
                `grid-${grid.split('/')[1]}`,
                {
                  resources: index === 4,
                  split: subItems
                    .filter(({ section }) => section === 'aside')
                    .filter(({ isDisplayedOnlyWhenLoggedIn }) => {
                      if (isDisplayedOnlyWhenLoggedIn) {
                        return isLogged;
                      }

                      return true;
                    })
                    .length === 2,
                },
              ]"
            >
              <AtomAnchor
                v-for="subItem in subItems
                  .filter(({ section }) => section === 'aside')
                  .filter(({ isDisplayedOnlyWhenLoggedIn }) => {
                    if (isDisplayedOnlyWhenLoggedIn) {
                      return isLogged;
                    }

                    return true;
                  })
                "
                :key="subItem.id"
                class="subitem"
                :force-no-follow="subItem.isNoFollow"
                :to="subItem.slug"
                @click="
                  track('Navigation bar clicked', {
                    category: 'Button',
                    label: subItem.slug,
                    locale,
                  })
                "
              >
                <UiComponentImage
                  v-if="subItem.image"
                  :alt="subItem.image.alt"
                  class="illustration"
                  :expected-size="
                    index === 4 && subItems
                      .filter(({ section }) => section === 'aside')
                      .filter(({ isDisplayedOnlyWhenLoggedIn }) => {
                        if (isDisplayedOnlyWhenLoggedIn) {
                          return isLogged;
                        }

                        return true;
                      })
                      .length === 2
                      ? 130
                      : Number.parseInt(subItem.image.customData.expectedSize)
                  "
                  :height="subItem.image.height"
                  :orientation="
                    index === 4 && subItems
                      .filter(({ section }) => section === 'aside')
                      .filter(({ isDisplayedOnlyWhenLoggedIn }) => {
                        if (isDisplayedOnlyWhenLoggedIn) {
                          return isLogged;
                        }

                        return true;
                      })
                      .length === 2
                      ? 'landscape'
                      : subItem.image.customData.orientation
                  "
                  :source="subItem.image.url"
                  :style="extractStyles(subItem.image.customData.style)"
                  :width="subItem.image.width"
                />
                <UiComponentWrapper class="inner">
                  <UiComponentWrapper class="head">
                    <UiComponentIcon
                      v-if="subItem.icon"
                      :type="subItem.icon.name"
                    />
                  </UiComponentWrapper>
                  <UiComponentWrapper class="body">
                    <UiComponentParagraph>{{ subItem.title }}</UiComponentParagraph>
                    <UiComponentParagraph>{{ subItem.subtitle }}</UiComponentParagraph>
                  </UiComponentWrapper>
                </UiComponentWrapper>
              </AtomAnchor>
            </UiComponentWrapper>
          </UiComponentContainer>

          <!-- Bottom -->
          <UiComponentWrapper class="bottom">
            <UiComponentContainer class="inner">
              <!-- Title -->
              <UiComponentParagraph class="title">
                {{
                  data.allSecondaryItems.title
                }}
              </UiComponentParagraph>

              <!-- Items -->
              <UiComponentWrapper class="list">
                <AtomAnchor
                  v-for="item in data.allSecondaryItems.items"
                  :key="item.id"
                  class="item"
                  :force-no-follow="item.isNoFollow"
                  :to="item.slug"
                  @click="
                    item.slug.startsWith('#')
                      ? requestADemo()
                      : track('Navigation bar clicked', {
                        category: 'Button',
                        label: item.slug,
                        locale,
                      })
                  "
                >
                  <UiComponentWrapper class="head">
                    <UiComponentIcon
                      v-if="item.icon"
                      :type="item.icon.name"
                    />
                  </UiComponentWrapper>
                  <UiComponentWrapper class="body">
                    <UiComponentParagraph>{{ item.title }}</UiComponentParagraph>
                    <UiComponentParagraph>{{ item.subtitle }}</UiComponentParagraph>
                    <AtomButton
                      v-if="item.slug === '#form-demo'"
                      :theme="props.theme"
                      type="text"
                    >
                      {{ data.allSecondaryItems.contactSalesCta
                      }}<UiComponentIcon type="arrow-right" />
                    </AtomButton>
                  </UiComponentWrapper>
                </AtomAnchor>
              </UiComponentWrapper>
            </UiComponentContainer>
          </UiComponentWrapper>
        </UiComponentWrapper>
      </UiComponentWrapper>
    </UiComponentWrapper>

    <!-- Mobile Items -->
    <UiComponentWrapper
      class="mobile-items"
      :class="{ opened: mobileMenuIsOpened }"
      :style="{ '--height': `${mobileMaxHeight}px` }"
    >
      <UiComponentWrapper
        v-for="(
          {
            id,
            title,
            sectionTitle,
            slug,
            items: subItems,
            hoverColor: backgroundColor,
            iconColor,
          },
          index
        ) in data.allPrimaryItems"
        :key="id"
        class="item"
        :class="{ active: index === selectedMobileSectionIndex }"
        :style="{
          '--color': backgroundColor && backgroundColor.hex,
        }"
      >
        <!-- Name -->
        <AtomAnchor
          v-if="slug"
          class="title"
          :to="isPricingPage(slug) ? localePath(toPricingPage) : slug"
          @click="
            track('Navigation bar clicked', {
              category: 'Button',
              label: slug,
              locale,
            })
          "
        >
          {{ title }}
        </AtomAnchor>
        <UiComponentParagraph
          v-else
          class="title"
          @click="toggleMobileSection(index)"
        >
          {{ title }}<UiComponentIcon type="chevron-down" />
        </UiComponentParagraph>

        <!-- Section (if it exists) -->
        <UiComponentWrapper
          v-if="!slug"
          class="section"
        >
          <!-- Top -->
          <UiComponentWrapper class="top">
            <!-- Main -->
            <UiComponentWrapper class="main">
              <UiComponentParagraph class="subtitle">
                {{ sectionTitle }}
              </UiComponentParagraph>
              <UiComponentWrapper class="subitems">
                <AtomAnchor
                  v-for="subItem in subItems
                    .filter(({ section }) => section === 'main')
                    .filter(({ isDisplayedOnlyWhenLoggedIn }) => {
                      if (isDisplayedOnlyWhenLoggedIn) {
                        return isLogged;
                      }

                      return true;
                    })
                  "
                  :key="subItem.id"
                  class="subitem"
                  :class="{ 'not-localized': subItem.isEnglishOnly }"
                  :force-no-follow="subItem.isNoFollow"
                  :force-reload="subItem.isEnglishOnly"
                  :to="subItem.slug"
                  @click="
                    track('Navigation bar clicked', {
                      category: 'Button',
                      label: subItem.slug,
                      locale,
                    })
                  "
                >
                  <UiComponentWrapper class="head">
                    <UiComponentIcon
                      v-if="subItem.icon"
                      :style="{ color: iconColor.hex }"
                      :type="subItem.icon.name"
                    />
                  </UiComponentWrapper>
                  <UiComponentWrapper class="body">
                    <UiComponentParagraph>{{ subItem.title }}</UiComponentParagraph>
                  </UiComponentWrapper>
                </AtomAnchor>
              </UiComponentWrapper>
              <AtomButton
                v-if="index === 1"
                :to="localePath('industries')"
                type="secondary"
              >
                {{ data.global.allIndustries }}<UiComponentIcon type="arrow-right" />
              </AtomButton>
            </UiComponentWrapper>

            <!-- Aside -->
            <UiComponentWrapper class="aside">
              <AtomAnchor
                v-for="subItem in subItems
                  .filter(({ section }) => section === 'aside')
                  .filter(({ isDisplayedOnlyWhenLoggedIn }) => {
                    if (isDisplayedOnlyWhenLoggedIn) {
                      return isLogged;
                    }

                    return true;
                  })
                "
                :key="subItem.id"
                class="subitem"
                :class="{
                  full:
                    subItems
                      .filter(({ section }) => section === 'aside')
                      .filter(({ isDisplayedOnlyWhenLoggedIn }) => {
                        if (isDisplayedOnlyWhenLoggedIn) {
                          return isLogged;
                        }

                        return true;
                      })
                      .length === 1,
                }"
                :force-no-follow="subItem.isNoFollow"
                :to="subItem.slug"
                @click="
                  track('Navigation bar clicked', {
                    category: 'Button',
                    label: subItem.slug,
                    locale,
                  })
                "
              >
                <UiComponentWrapper class="head">
                  <UiComponentIcon
                    v-if="subItem.icon"
                    :type="subItem.icon.name"
                  />
                </UiComponentWrapper>
                <UiComponentWrapper class="body">
                  <UiComponentParagraph>{{ subItem.title }}</UiComponentParagraph>
                  <UiComponentParagraph>{{ subItem.subtitle }}</UiComponentParagraph>
                </UiComponentWrapper>
              </AtomAnchor>
            </UiComponentWrapper>
          </UiComponentWrapper>

          <!-- Bottom -->
          <UiComponentWrapper class="bottom">
            <!-- Title -->
            <UiComponentParagraph class="title">
              {{
                data.allSecondaryItems.title
              }}
            </UiComponentParagraph>

            <!-- Items -->
            <UiComponentWrapper class="list">
              <AtomAnchor
                v-for="item in data.allSecondaryItems.items"
                :key="item.id"
                class="item"
                :force-no-follow="item.isNoFollow"
                :to="item.slug"
                @click="
                  item.slug.startsWith('#')
                    ? requestADemo()
                    : track('Navigation bar clicked', {
                      category: 'Button',
                      label: item.slug,
                      locale,
                    })
                "
              >
                <UiComponentWrapper class="head">
                  <UiComponentIcon
                    v-if="item.icon"
                    :type="item.icon.name"
                  />
                </UiComponentWrapper>
                <UiComponentWrapper class="body">
                  <UiComponentParagraph>{{ item.title }}</UiComponentParagraph>
                </UiComponentWrapper>
              </AtomAnchor>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>
      </UiComponentWrapper>

      <!-- Actions -->
      <UiComponentWrapper class="button-list">
        <AtomButton
          v-if="!isLogged"
          :action="loginCallToAction.action"
          class="log-in"
          label="Navigation bar CTA"
          size="l"
          :to="loginCallToAction.to"
          type="text"
        >
          {{ loginCallToAction.slot }}
        </AtomButton>
        <AtomButton
          :action="requestDemoCallToAction.action"
          class="demo"
          label="Navigation bar CTA"
          size="l"
          :to="requestDemoCallToAction.to"
          type="secondary"
        >
          {{ requestDemoCallToAction.slot }}
        </AtomButton>
        <AtomButton
          :action="signupOrMyDashboardCallToAction.action"
          class="sign-up"
          label="Navigation bar CTA"
          size="l"
          :to="signupOrMyDashboardCallToAction.to"
        >
          {{ signupOrMyDashboardCallToAction.slot }}
        </AtomButton>
      </UiComponentWrapper>
    </UiComponentWrapper>

    <!-- Actions -->
    <UiComponentWrapper class="actions">
      <!-- Mobile Only -->
      <UiComponentWrapper
        class="burger"
        @click="toggleMobileMenu"
      >
        <UiComponentIcon
          :theme="props.theme"
          :type="iconType"
        />
      </UiComponentWrapper>

      <!-- Desktop Only -->
      <AtomButton
        v-if="!isLogged"
        :action="loginCallToAction.action"
        class="log-in"
        label="Navigation bar CTA"
        :to="loginCallToAction.to"
        type="text"
      >
        {{ loginCallToAction.slot }}
      </AtomButton>
      <AtomButton
        :action="requestDemoCallToAction.action"
        class="demo"
        label="Navigation bar CTA"
        :to="requestDemoCallToAction.to"
        type="secondary"
      >
        {{ requestDemoCallToAction.slot }}
      </AtomButton>
      <AtomButton
        :action="signupOrMyDashboardCallToAction.action"
        class="sign-up"
        label="Navigation bar CTA"
        :theme="props.theme"
        :to="signupOrMyDashboardCallToAction.to"
      >
        {{ signupOrMyDashboardCallToAction.slot }}
      </AtomButton>
    </UiComponentWrapper>
  </nav>
</template>

<script setup>
// Composables
const { track } = useAnalyticsJS()
const { state } = useConditionalPreviewMode()
const experiences = useCookie('experiences')
const { locale } = useI18n()
const { isLogged } = useIsLoggedIn()
const { public: publicRuntimeConfig } = useRuntimeConfig()
const route = useRoute()

// Directives
const { vClickOutside } = useVClickOutside()

// Props
const props = defineProps({
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
})

// Async Data
const { data } = await useFetch(`/api/content/components/navigation/${locale.value}`, {
  key: `component-navigation-${locale.value}`,
  query: {
    preview: state.preview,
  },
})

// States
const forms = useState('forms')

// Refs
const $el = ref(null)
const mobileMaxHeight = ref(0)
const mobileMenuIsOpened = ref(false)
const selectedDesktopSectionIndex = ref(null)
const selectedMobileSectionIndex = ref(null)

// Computed Properties
const iconType = computed(() => {
  return mobileMenuIsOpened.value ? 'x' : 'menu'
})

const loginCallToAction = computed(() => {
  return {
    action: 'Log in clicked',
    slot: data.value.global.logIn,
    to: `https://${publicRuntimeConfig.appBaseUrl}/#/login`,
  }
})

const requestDemoCallToAction = computed(() => {
  return {
    action: 'Demo clicked',
    slot: data.value.global.ctaGetDemo,
    to: '#form-demo',
  }
})

const signupOrMyDashboardCallToAction = computed(() => {
  return isLogged.value
    ? {
        action: 'My dashboard clicked',
        to: `https://${publicRuntimeConfig.appBaseUrl}/`,
        slot: data.value.global.myDashboard,
      }
    : {
        action: 'Signup clicked',
        to: `https://${publicRuntimeConfig.appBaseUrl}/#/signup`,
        slot: data.value.global.ctaGetStartedForFree,
      }
})

const toPricingPage = computed(() => {
  return variation.value === 'test' ? 'pricing-plans' : 'pricing'
})

const variation = computed(() => {
  const experience = (experiences.value || []).find(({ experience }) => experience === 'pricing')
  return experience ? experience.variation : 'control'
})

// Watchers
watch(() => mobileMenuIsOpened.value, (opened) => {
  if (opened) {
    document.body.classList.add('no-scroll')
    onResize()
  }
  else {
    document.body.classList.remove('no-scroll')
  }
})

watch(() => route.path, () => {
  mobileMenuIsOpened.value = false
  selectedDesktopSectionIndex.value = null
  selectedMobileSectionIndex.value = null
})

watch(() => selectedMobileSectionIndex.value, () => {
  onResize()
})

// Methods
function extractStyles(data) {
  if (!data) {
    return {}
  }
  return JSON.parse(data)
}

function isPricingPage(slug) {
  return ['pricing', 'es/precios', 'fr/tarifs'].includes(slug)
}

function onClickOutside() {
  if (!mobileMenuIsOpened.value) {
    return
  }

  mobileMenuIsOpened.value = false
}

function onResize() {
  const offsetTop = $el.value
    .querySelector('.mobile-items')
    .getBoundingClientRect().top
  mobileMaxHeight.value
    = Math.max(window.innerHeight, document.documentElement.clientHeight)
    - offsetTop
}

function requestADemo() {
  track('Demo clicked', { category: 'Button' })
  forms.value.demo.isVisible = true
}

function toggleMobileSection(index) {
  if (selectedMobileSectionIndex.value === index) {
    selectedMobileSectionIndex.value = null
  }
  else {
    selectedMobileSectionIndex.value = index
  }
}

function toggleMobileMenu() {
  mobileMenuIsOpened.value = !mobileMenuIsOpened.value
}

// Lifecycle Hooks
onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})

onMounted(() => {
  window.addEventListener('resize', onResize)
  onResize()
})
</script>

<style lang="postcss" scoped>
.navigation {
  @apply relative flex flex-row items-center justify-between w-full py-4 bg-transparent transition-colors duration-300 ease-in-out;

  @screen xl {
    @apply justify-start py-0;
  }

  &::before,
  &::after {
    @apply absolute h-full w-4 bg-transparent content-[''] transition-colors duration-300 ease-in-out;

    @screen md {
      @apply w-6;
    }

    @screen lg {
      width: calc((100vw - 100%) / 2);
    }

    @screen xl {
      width: calc((100vw - 1280px) / 2);
    }
  }

  &::before {
    @apply -left-4;

    @screen md {
      @apply -left-6;
    }

    @screen lg {
      left: calc((100vw - 100%) / -2);
    }
  }

  &::after {
    @apply -right-4;

    @screen md {
      @apply -right-6;
    }

    @screen lg {
      right: calc((100vw - 100%) / -2);
    }
  }

  &.dark {
    .desktop-items {
      > .item {
        &.active {
          .title {
            @apply text-winter-green-900;

            i {
              @apply text-winter-green-900;
            }
          }
        }

        &.no-subitems {
          &:hover {
            .title {
              @apply text-white;
            }
          }
        }

        &::before {
          @apply border-winter-green-900;
        }

        .title {
          @apply text-[#CED0D0];

          i {
            @apply text-[#CED0D0];
          }
        }

        .section {
          .bottom {
            .inner {
              .list {
                .item {
                  .body {
                    .button {
                      @apply text-winter-green-800;

                      i {
                        @apply text-winter-green-800;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .actions {
      .button {
        &.log-in {
          @apply text-[#CED0D0];

          &:hover {
            @apply text-white;
          }
        }
      }
    }
  }

  &.light {
    .desktop-items {
      > .item {
        &.active {
          .title {
            @apply text-livestorm-blue-700;

            i {
              @apply text-livestorm-blue-700;
            }
          }
        }

        &.no-subitems {
          &:hover {
            .title {
              @apply text-livestorm-blue-700;
            }
          }
        }

        &::before {
          @apply border-livestorm-blue-700;
        }

        .title {
          @apply text-grey-blue-600;

          i {
            @applytext-grey-blue-600;
          }
        }

        .section {
          .bottom {
            .inner {
              .list {
                .item {
                  .body {
                    .button {
                      @apply text-livestorm-blue-700;

                      i {
                        @apply text-livestorm-blue-700;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .actions {
      .button {
        &.log-in {
          @apply text-grey-blue-600;
        }
      }
    }
  }

  &.is-opaque {
    @apply bg-white;

    &::before,
    &::after {
      @apply bg-white;
    }

    .desktop-items {
      > .item {
        .title {
          @apply text-grey-blue-600;

          i {
            @apply text-grey-blue-600;
          }
        }
      }
    }

    .actions {
      .burger {
        i {
          @apply text-winter-green-900;
        }
      }

      .button {
        &.log-in {
          @apply text-grey-blue-600;
        }
      }
    }
  }

  .logo {
    @apply w-auto;

    @screen xl {
      @apply transform -translate-y-[2.45px];
    }

    :deep(img) {
      @apply h-5 w-auto;

      @screen xl {
        @apply h-6;
      }
    }
  }

  .desktop-items {
    @apply hidden;

    @screen xl {
      @apply flex items-center h-[88px] w-auto ml-[22px];
    }

    > .item {
      @apply relative items-center h-full px-[10px];

      &.active {
        &::before {
          width: calc(100% - 20px);
        }

        .title {
          i {
            @apply translate-y-0;
          }
        }

        .section {
          @apply max-h-[2000px] opacity-100 shadow-light2;
        }
      }

      &.no-subitems {
        &:hover {
          .title {
            i {
              @apply -translate-y-[2px];
            }
          }
        }
      }

      &::before {
        @apply absolute bottom-0 left-1/2 right-1/2 w-0 border-t content-[''] transition-all duration-300 ease-in-out;
        transform: translateX(calc(-50% - 2px));
      }

      .title {
        @apply flex items-end text-base leading-[21px] cursor-pointer transition-colors duration-300 ease-in-out;

        i {
          @apply ml-1 text-[10px] leading-6 transform -translate-y-1 transition-all duration-600 ease-in-out;
        }
      }

      .section {
        @apply fixed bottom-0 left-0 flex-col items-center w-screen max-h-0 bg-white border-t border-grey-blue-100 opacity-0 transform translate-y-full overflow-hidden transition-all duration-600 ease-in-out;

        .top {
          @apply justify-between py-6;

          .main {
            @apply flex-col;

            &.grid-8 {
              @apply w-span8;

              .subitem {
                @apply w-100;
              }
            }

            &.grid-9 {
              @apply w-span9 pb-12;

              .subitem {
                @apply w-[290px];
              }
            }

            .subtitle {
              @apply font-medium text-lg text-winter-green-900 leading-[23.4px];
            }

            .subitems {
              @apply flex-wrap justify-between mt-2;

              .subitem {
                @apply relative flex min-h-[92px] p-4 pl-0 rounded-3xl cursor-pointer;

                &::before {
                  @apply absolute -left-4 top-0 h-full rounded-3xl content-[''] z-0 transition-colors duration-300 ease-in-out;
                  width: calc(100% + 16px);
                }

                &.not-localized {
                  .head {
                    &::after {
                      @apply content-['en'];
                    }
                  }
                }

                &:hover {
                  &::before {
                    background-color: var(--color);
                  }
                }

                .head {
                  @apply relative h-10 w-10 p-3 rounded z-10;
                  background-color: var(--color);

                  &::after {
                    @apply absolute bottom-0 right-0 px-1 pb-1 pt-[5px] bg-grey-blue-600 font-bold text-grey-blue-100 text-[10px] leading-[8px] uppercase rounded transform translate-x-1 translate-y-1/2;
                  }

                  i {
                    @apply text-base text-grey-blue-400 leading-none;
                  }
                }

                .body {
                  @apply flex-col ml-4 z-10;

                  p {
                    @apply font-medium text-sm text-winter-green-900 leading-6;
                  }

                  p + p {
                    @apply mt-1 font-normal text-xs text-grey-blue-600 leading-4;
                  }
                }
              }
            }

            .button {
              @apply mt-6 bg-grey-blue-100 font-medium text-sm text-winter-green-900 leading-6;

              &:hover {
                @apply bg-grey-blue-200;
              }
            }
          }

          .aside {
            @apply flex-col;

            &.grid-3 {
              @apply w-span3 mt-8;
            }

            &.grid-4 {
              @apply w-span4;
            }

            &.resources {
              &.split {
                .subitem {
                  @apply flex-row items-center justify-between p-6;

                  .illustration {
                    @apply order-last mb-0 !important;
                  }

                  .inner {
                    @apply max-w-[214px];
                  }
                }
              }
            }

            .subitem {
              @apply relative flex flex-col mt-6 p-6 bg-grey-blue-100 rounded-3xl overflow-hidden cursor-pointer transition-colors duration-300 ease-in-out;

              &:first-child {
                @apply mt-0;
              }

              &:hover {
                @apply bg-grey-blue-200;
              }

              .illustration {
                @apply rounded-none;
              }

              .inner {
                .head {
                  @apply h-6 w-6;

                  i {
                    @apply text-2xl text-grey-blue-400 leading-none;
                  }
                }

                .body {
                  @apply flex-col ml-4;

                  p {
                    @apply font-medium text-origin text-winter-green-900 leading-[23.4px];
                  }

                  p + p {
                    @apply mt-1 font-normal text-xs text-grey-blue-600 leading-4;
                  }
                }
              }
            }
          }
        }

        .bottom {
          @apply justify-center mt-6 bg-grey-blue-100;

          .inner {
            @apply relative;

            .title {
              @apply absolute top-px px-4 pt-2 pb-1 bg-grey-blue-100 font-medium text-winter-green-900 text-sm leading-[18px] rounded-t transform -translate-y-full cursor-default;
            }

            .list {
              @apply py-2;

              .item {
                @apply relative flex items-start w-100 mx-0 pt-4 pb-4 pr-4 pl-0 cursor-pointer;

                &:hover {
                  &::before {
                    @apply bg-grey-blue-200;
                  }

                  .body {
                    .button {
                      :deep(i) {
                        @apply translate-x-2;
                      }
                    }
                  }
                }

                &:nth-child(2) {
                  @apply ml-10;
                }

                &:nth-child(3) {
                  @apply w-[384px] ml-auto;
                }

                &::before {
                  @apply absolute -left-4 top-0 h-full bg-grey-blue-100 rounded-3xl content-[''] z-0 transition-colors duration-300 ease-in-out;
                  width: calc(100% + 16px);
                }

                .head {
                  @apply h-10 w-10 p-3 bg-grey-blue-100 rounded z-10;

                  i {
                    @apply text-base text-grey-blue-400 leading-none;
                  }
                }

                .body {
                  @apply flex-col ml-4 z-10;

                  p {
                    @apply font-medium text-sm text-winter-green-900 leading-6;
                  }

                  p + p {
                    @apply font-normal text-xs text-grey-blue-600 leading-4;
                  }

                  .button {
                    @apply self-start mt-1 h-auto px-0 font-medium text-sm leading-none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .mobile-items {
    @apply fixed top-13 left-0 right-0 flex-col max-h-0 bg-white overflow-scroll transition-all duration-300 ease-in-out;

    @screen xl {
      @apply hidden;
    }

    &.opened {
      @apply border-t border-grey-blue-100 shadow-light2;
      max-height: var(--height);
    }

    .item {
      @apply flex-col;

      &.active {
        .title {
          @apply font-medium text-winter-green-900;

          i {
            @apply text-livestorm-blue-600 rotate-180;
          }
        }

        .section {
          @apply max-h-[2000px];
        }
      }

      .title {
        @apply flex items-center justify-between w-full p-4 text-origin text-grey-blue-600 leading-7 transition-colors duration-300 ease-in-out cursor-pointer;

        i {
          @apply text-2xl text-grey-blue-600 leading-none transform origin-center rotate-0 transition-all duration-300 ease-in-out;
        }
      }

      .section {
        @apply flex-col max-h-0 border-t border-grey-blue-100 overflow-hidden transition-all duration-500 ease-in-out;

        .top {
          @apply flex-col;

          .main {
            @apply flex-col;

            .subtitle {
              @apply mt-4 px-4 font-medium text-winter-green-900 text-base leading-[21px];

              @screen md {
                @apply px-6;
              }
            }

            .subitems {
              @apply flex-row flex-wrap mt-2;

              @screen md {
                @apply px-2;
              }

              .subitem {
                @apply flex items-center w-1/2 p-4;

                @screen md {
                  @apply mx-3;
                  width: calc((100% - 48px) / 3);
                }

                &:nth-child(3n + 1) {
                  @screen md {
                    @apply ml-0;
                  }
                }

                &:nth-child(3n + 3) {
                  @screen md {
                    @apply mr-0;
                  }
                }

                &.not-localized {
                  .head {
                    &::after {
                      @apply content-['en'];
                    }
                  }
                }

                .head {
                  @apply relative h-10 w-10 p-3 rounded;
                  background-color: var(--color);

                  &::after {
                    @apply absolute bottom-0 right-0 px-1 pb-1 pt-[5px] bg-grey-blue-600 font-bold text-grey-blue-100 text-[10px] leading-[8px] uppercase rounded transform translate-x-1 translate-y-1/2;
                  }

                  i {
                    @apply text-base text-grey-blue-400 leading-none;
                  }
                }

                .body {
                  @apply ml-4;

                  p {
                    @apply font-medium text-winter-green-900 text-sm leading-6;
                  }
                }
              }
            }

            .button {
              @apply mt-4 mx-4 bg-grey-blue-100 text-sm leading-6;

              @screen md {
                @apply mx-6;
              }
            }
          }

          .aside {
            @apply flex-col p-4;

            @screen md {
              @apply flex-row flex-wrap p-6;
            }

            .subitem {
              @apply flex mt-4 p-4 bg-grey-blue-100 rounded-2xl;

              @screen md {
                @apply mt-0 mx-5 p-6 rounded-3xl;
                width: calc((100% - 40px) / 2);
              }

              &:nth-child(1) {
                @apply mt-0;
              }

              &:nth-child(2n + 1) {
                @screen md {
                  @apply ml-0;
                }
              }

              &:nth-child(2n + 2) {
                @screen md {
                  @apply mr-0;
                }
              }

              &.full {
                @screen md {
                  @apply w-full mx-0;
                }
              }

              .head {
                @apply h-6 w-6;

                i {
                  @apply text-2xl text-grey-blue-400 leading-none;
                }
              }

              .body {
                @apply flex-col ml-4;

                p {
                  @apply font-medium text-winter-green-900 text-origin leading-[23.4px];
                }

                p + p {
                  @apply mt-1 font-normal text-grey-blue-600 text-xs leading-4;
                }
              }
            }
          }
        }

        .bottom {
          @apply relative flex-col mt-[30px] bg-grey-blue-100;

          .title {
            @apply absolute top-px left-4 w-auto px-4 pt-2 pb-1 bg-grey-blue-100 font-medium text-grey-blue-800 text-sm leading-[18.2px] rounded-t-xl transform -translate-y-full;

            @screen md {
              @apply left-6;
            }
          }

          .list {
            @apply flex-col;

            @screen md {
              @apply flex-row flex-wrap;
            }

            .item {
              @apply flex flex-row items-center px-4 py-1;

              @screen md {
                @apply w-1/3 p-4;
              }

              .head {
                @apply h-10 w-10 p-3;

                i {
                  @apply text-base text-grey-blue-400 leading-none;
                }
              }

              .body {
                p {
                  @apply font-medium text-winter-green-900 text-sm leading-6;
                }
              }
            }
          }
        }
      }
    }

    .button-list {
      @apply flex-col py-6 px-4;

      @screen md {
        @apply px-6;
      }

      .button {
        @apply mt-4;

        &:nth-child(1) {
          @apply mt-0;
        }

        &.text {
          @apply font-bold;
        }
      }
    }
  }

  .actions {
    @apply items-center w-auto;

    @screen xl {
      @apply ml-auto;
    }

    .burger {
      @apply relative h-6 w-6 cursor-pointer;

      @screen xl {
        @apply hidden;
      }

      i {
        @apply text-2xl leading-none;
      }
    }

    .button {
      @apply hidden;

      @screen xl {
        @apply flex text-base;
      }

      &.log-in {
        @apply ml-0 mr-2 px-0 font-normal leading-[21px];
      }

      &.demo {
        @apply mx-2;
      }

      &.sign-up {
        @apply ml-2 mr-0;
      }
    }
  }
}
</style>
