<template>
  <div
    ref="$el"
    class="logo"
    @contextmenu.prevent="onContextMenu"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <NuxtLink :to="localePath('/')">
      <Transition
        mode="out-in"
        name="fade"
      >
        <img
          v-if="props.theme === 'light'"
          alt="Livestorm logo"
          height="24"
          loading="lazy"
          src="@/assets/svg/logo-black.svg"
          width="160"
        >
        <img
          v-else
          alt="Livestorm logo"
          height="24"
          loading="lazy"
          src="@/assets/svg/logo-white.svg"
          width="160"
        >
      </Transition>
    </NuxtLink>
    <template v-if="props.theme === 'light' && isLottieFrameworkLoaded">
      <lottie-player
        v-for="(animation, index) in animations"
        class="lottie"
        :class="{ active: index === currentIndex }"
        :src="`/lottie/logo/${animation}.json`"
      />
    </template>
    <Transition
      @enter="onEnter"
      @leave="onLeave"
    >
      <div
        v-if="contextMenuIsDisplayed"
        v-click-outside="closeContextMenu"
        class="context-menu"
        :style="{ transform: `translate(${tooltip.x}px, ${tooltip.y}px)` }"
        @mousemove="onMouseMove"
      >
        <AtomAnchor
          class="item link"
          target="_blank"
          :to="localePath('/')"
        >
          <UiComponentIcon type="external-link" />
          {{ data.logo.openLinkInNewTabTitle }}
        </AtomAnchor>
        <div class="item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clip-path="url(#clip0_2581_7166)">
              <path
                d="M15.9993 7.99904C15.9993 10.2086 16.0734 12.7818 14.4288 14.432C12.7842 16.0822 10.2096 15.9983 8.00002 15.9983C5.79042 15.9983 3.21722 16.0725 1.56701 14.4278C-0.0831938 12.7832 0.000714963 10.2086 0.000714963 7.99904C0.000714963 5.78944 -0.072006 3.21624 1.57261 1.56603C3.21722 -0.0841704 5.79182 -0.000261599 8.00002 -0.000261599C10.2082 -0.000261599 12.7828 -0.0729825 14.433 1.57163C16.0832 3.21624 15.9993 5.79084 15.9993 7.99904Z"
                fill="#12262B"
              />
              <path
                d="M8.90139 3.81738H3.78435C3.33175 3.81738 2.96484 4.18429 2.96484 4.63689C2.96484 5.08949 3.33175 5.4564 3.78435 5.4564H8.90139C9.35399 5.4564 9.7209 5.08949 9.7209 4.63689C9.7209 4.18429 9.35399 3.81738 8.90139 3.81738Z"
                fill="white"
              />
              <path
                d="M10.5772 7.17969H5.46013C5.00753 7.17969 4.64062 7.54659 4.64062 7.9992C4.64062 8.4518 5.00753 8.81871 5.46013 8.81871H10.5772C11.0298 8.81871 11.3967 8.4518 11.3967 7.9992C11.3967 7.54659 11.0298 7.17969 10.5772 7.17969Z"
                fill="white"
              />
              <path
                d="M8.90219 10.543H7.13592C6.68331 10.543 6.31641 10.9099 6.31641 11.3625C6.31641 11.8151 6.68331 12.182 7.13592 12.182H8.90219C9.3548 12.182 9.7217 11.8151 9.7217 11.3625C9.7217 10.9099 9.3548 10.543 8.90219 10.543Z"
                fill="white"
              />
              <path
                d="M13.0335 4.63689C13.0335 4.18429 12.6666 3.81738 12.214 3.81738C11.7614 3.81738 11.3945 4.18429 11.3945 4.63689C11.3945 5.08949 11.7614 5.4564 12.214 5.4564C12.6666 5.4564 13.0335 5.08949 13.0335 4.63689Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2581_7166">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
          {{ data.logo.downloadIconTitle }}
          <a
            class="tag"
            download="true"
            :href="`${data.logo.downloadIconPng.url}?dl=icon.png`"
          >
            <UiComponentIcon type="download" />
            PNG
          </a>
          <a
            class="tag"
            download="true"
            :href="`${data.logo.downloadIconSvg.url}?dl=icon.svg`"
          >
            <UiComponentIcon type="download" />
            SVG
          </a>
        </div>
        <div class="item">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2529_9765)">
              <g clip-path="url(#clip1_2529_9765)">
                <path
                  d="M6.97266 6.23889V9.586H9.28086V8.91846H7.75303V6.23889H6.97266Z"
                  fill="#12262B"
                />
                <path
                  d="M9.74552 6.73249C9.82074 6.80771 9.92416 6.84532 10.0464 6.84532C10.1686 6.84532 10.2673 6.80771 10.3473 6.73249C10.4225 6.65728 10.4648 6.55856 10.4648 6.43633C10.4648 6.31881 10.4225 6.22008 10.3473 6.14487C10.2673 6.06965 10.1686 6.02734 10.0464 6.02734C9.92416 6.02734 9.82074 6.06965 9.74552 6.14487C9.6656 6.22008 9.628 6.31881 9.628 6.43633C9.628 6.55856 9.6656 6.65728 9.74552 6.73249ZM9.69851 7.17909V9.586H10.3943V7.17909H9.69851Z"
                  fill="#12262B"
                />
                <path
                  d="M10.7115 7.17909L11.5483 9.586H12.5543L13.3911 7.17909H12.6624L12.0748 9.01718H12.0278L11.4449 7.17909H10.7115Z"
                  fill="#12262B"
                />
                <path
                  d="M14.136 9.50138C14.3381 9.60951 14.5685 9.66122 14.827 9.66122C15.1185 9.66122 15.3723 9.6001 15.5886 9.47788C15.8048 9.35565 15.9506 9.18172 16.0258 8.96547L15.4335 8.81504C15.377 8.91846 15.3018 8.99838 15.2031 9.05479C15.0997 9.1159 14.9869 9.14411 14.8552 9.14411C14.686 9.14411 14.5403 9.0971 14.4274 8.99368C14.3146 8.89495 14.2441 8.75862 14.2159 8.58469H16.1057C16.1104 8.55178 16.1151 8.48127 16.1151 8.37314C16.1151 8.12399 16.0587 7.90304 15.9506 7.7103C15.8424 7.52226 15.692 7.37183 15.4946 7.26371C15.2971 7.16028 15.0762 7.10387 14.8223 7.10387C14.5638 7.10387 14.3334 7.16028 14.136 7.26371C13.9338 7.36713 13.7787 7.51756 13.6659 7.7056C13.5531 7.89834 13.4966 8.11929 13.4966 8.36374C13.4966 8.6176 13.5531 8.84324 13.6659 9.04068C13.7787 9.23813 13.9338 9.39326 14.136 9.50138ZM15.4053 8.1522H14.2112C14.2394 7.99236 14.3052 7.86073 14.4086 7.76671C14.5121 7.67269 14.6437 7.62098 14.8035 7.62098C14.9634 7.62098 15.095 7.67269 15.2078 7.77142C15.3159 7.87014 15.3817 7.99706 15.4053 8.1522Z"
                  fill="#12262B"
                />
                <path
                  d="M16.7497 9.43557C16.9519 9.5954 17.2339 9.67062 17.5912 9.67062C17.9438 9.67062 18.2212 9.6001 18.4233 9.45437C18.6255 9.31334 18.7289 9.1112 18.7289 8.85265C18.7289 8.67401 18.6772 8.53768 18.5831 8.43426C18.4891 8.33083 18.3716 8.26032 18.2306 8.21801C18.0895 8.1757 17.9297 8.14279 17.7464 8.11929C17.5771 8.10049 17.4549 8.08638 17.3844 8.07228C17.3139 8.06288 17.2528 8.03937 17.1963 8.00176C17.1399 7.96416 17.1164 7.91245 17.1164 7.84193C17.1164 7.75731 17.154 7.6868 17.2339 7.63509C17.3092 7.58337 17.4126 7.55517 17.5442 7.55517C17.6852 7.55517 17.7981 7.58808 17.8827 7.65389C17.9673 7.7197 18.0143 7.80432 18.019 7.90774H18.6537C18.6537 7.74791 18.6066 7.60218 18.5126 7.47995C18.4186 7.35773 18.287 7.25901 18.1177 7.19319C17.9485 7.12738 17.7558 7.09447 17.5442 7.09447C17.2198 7.09447 16.9613 7.16969 16.7638 7.31542C16.5664 7.46115 16.4724 7.65389 16.4724 7.89834C16.4724 8.09108 16.5241 8.23681 16.6275 8.34024C16.7309 8.44366 16.8485 8.51417 16.9895 8.55178C17.1305 8.58939 17.2998 8.61759 17.5066 8.6364C17.6899 8.6552 17.8263 8.68341 17.9156 8.71632C18.0002 8.74922 18.0472 8.81504 18.0472 8.91376C18.0472 9.00778 18.0049 9.07829 17.925 9.13C17.8451 9.18642 17.7417 9.20992 17.61 9.20992C17.4408 9.20992 17.3045 9.17231 17.2057 9.0924C17.107 9.01718 17.06 8.91376 17.06 8.78213H16.4442C16.4442 9.05949 16.5429 9.27573 16.7497 9.43557Z"
                  fill="#12262B"
                />
                <path
                  d="M19.5684 9.42617C19.7095 9.586 19.9022 9.66122 20.1467 9.66122C20.2172 9.66122 20.2971 9.65652 20.3864 9.64241C20.471 9.62831 20.551 9.60951 20.6168 9.586V9.0877C20.5228 9.1112 20.4381 9.1206 20.3676 9.1206C20.2689 9.1206 20.189 9.0924 20.1326 9.03128C20.0762 8.97017 20.0479 8.88555 20.0479 8.78213V7.6868H20.598V7.17909H20.0479L20.0526 6.41753H19.5073L19.371 7.17909H19.0137V7.6868H19.3522V8.81034C19.3522 9.06419 19.4227 9.27103 19.5684 9.42617Z"
                  fill="#12262B"
                />
                <path
                  d="M21.5132 9.50608C21.7107 9.60951 21.9457 9.66122 22.2137 9.66122C22.4769 9.66122 22.7073 9.60951 22.9094 9.50608C23.1116 9.40266 23.2667 9.25223 23.3795 9.05949C23.4924 8.86675 23.5488 8.6411 23.5488 8.38255C23.5488 8.12869 23.4924 7.90304 23.3795 7.7103C23.2667 7.51756 23.1116 7.36713 22.9094 7.26371C22.7073 7.16028 22.4769 7.10387 22.2137 7.10387C21.9457 7.10387 21.7107 7.16028 21.5132 7.26371C21.3111 7.36713 21.156 7.51756 21.0431 7.7103C20.9303 7.90304 20.8786 8.12869 20.8786 8.38255C20.8786 8.6411 20.9303 8.86675 21.0431 9.05949C21.156 9.25223 21.3111 9.40266 21.5132 9.50608ZM22.6697 8.87615C22.5475 9.00778 22.397 9.06889 22.2137 9.06889C22.0256 9.06889 21.8705 9.00778 21.753 8.87615C21.6308 8.74922 21.5743 8.58469 21.5743 8.38255C21.5743 8.1851 21.6308 8.02057 21.753 7.89364C21.8705 7.76671 22.0256 7.7009 22.2137 7.7009C22.397 7.7009 22.5475 7.76671 22.6697 7.89364C22.7872 8.02057 22.8483 8.1851 22.8483 8.38255C22.8483 8.58469 22.7872 8.74922 22.6697 8.87615Z"
                  fill="#12262B"
                />
                <path
                  d="M23.9709 7.17909V9.586H24.6666V8.35434C24.6666 8.171 24.7136 8.02057 24.8077 7.90304C24.9017 7.79022 25.0239 7.72911 25.179 7.72911C25.2307 7.72911 25.2919 7.73851 25.3671 7.75261C25.4423 7.77142 25.5081 7.79022 25.5739 7.81842V7.19319C25.4893 7.15558 25.4 7.13208 25.3013 7.13208C25.1743 7.13208 25.0568 7.16969 24.9487 7.2355C24.8406 7.30602 24.7606 7.39533 24.7089 7.50816H24.6572V7.17909H23.9709Z"
                  fill="#12262B"
                />
                <path
                  d="M25.9402 7.17909V9.586H26.636V8.21331C26.636 8.06288 26.6736 7.93595 26.7582 7.84193C26.8381 7.74791 26.9463 7.7009 27.0873 7.7009C27.2189 7.7009 27.3223 7.74791 27.4022 7.84193C27.4775 7.93595 27.5198 8.05818 27.5198 8.21331V9.586H28.2202V8.21331C28.2202 8.06288 28.2625 7.94065 28.3472 7.84663C28.4318 7.75261 28.5446 7.7009 28.6809 7.7009C28.8126 7.7056 28.9207 7.75731 29.0053 7.85133C29.0899 7.94535 29.1322 8.06758 29.1322 8.21331V9.586H29.828V8.09578C29.828 7.90774 29.7857 7.73381 29.7104 7.58337C29.6305 7.43294 29.5224 7.32012 29.3861 7.2355C29.245 7.15088 29.0899 7.10857 28.916 7.10857C28.7467 7.10857 28.5963 7.15088 28.4647 7.2308C28.333 7.31072 28.2343 7.41884 28.1591 7.55987H28.1121C28.0463 7.42354 27.9476 7.31542 27.8112 7.2308C27.6749 7.14618 27.5292 7.10387 27.374 7.10387C27.2236 7.10387 27.0873 7.14618 26.9651 7.2214C26.8428 7.30131 26.7441 7.40474 26.6783 7.53637H26.6313V7.17909H25.9402Z"
                  fill="#12262B"
                />
                <g clip-path="url(#clip2_2529_9765)">
                  <path
                    d="M5.04317 7.80783C5.04317 8.50432 5.06653 9.31542 4.54813 9.83559C4.02973 10.3558 3.21819 10.3293 2.5217 10.3293C1.82521 10.3293 1.0141 10.3527 0.49394 9.83426C-0.0262236 9.31586 0.000225365 8.50432 0.000225365 7.80783C0.000225365 7.11134 -0.0226971 6.30024 0.495703 5.78007C1.0141 5.25991 1.82565 5.28636 2.5217 5.28636C3.21775 5.28636 4.02929 5.26344 4.54945 5.78184C5.06962 6.30024 5.04317 7.11178 5.04317 7.80783Z"
                    fill="#12262B"
                  />
                  <path
                    d="M2.80486 6.48926H1.19191C1.04925 6.48926 0.933594 6.60491 0.933594 6.74758C0.933594 6.89024 1.04925 7.0059 1.19191 7.0059H2.80486C2.94753 7.0059 3.06318 6.89024 3.06318 6.74758C3.06318 6.60491 2.94753 6.48926 2.80486 6.48926Z"
                    fill="white"
                  />
                  <path
                    d="M3.3322 7.54883H1.71926C1.57659 7.54883 1.46094 7.66448 1.46094 7.80715C1.46094 7.94981 1.57659 8.06547 1.71926 8.06547H3.3322C3.47487 8.06547 3.59052 7.94981 3.59052 7.80715C3.59052 7.66448 3.47487 7.54883 3.3322 7.54883Z"
                    fill="white"
                  />
                  <path
                    d="M2.80726 8.60938H2.25051C2.10784 8.60938 1.99219 8.72503 1.99219 8.86769C1.99219 9.01036 2.10784 9.12601 2.25051 9.12601H2.80726C2.94992 9.12601 3.06558 9.01036 3.06558 8.86769C3.06558 8.72503 2.94992 8.60938 2.80726 8.60938Z"
                    fill="white"
                  />
                  <path
                    d="M4.11039 6.74758C4.11039 6.60491 3.99473 6.48926 3.85207 6.48926C3.7094 6.48926 3.59375 6.60491 3.59375 6.74758C3.59375 6.89024 3.7094 7.0059 3.85207 7.0059C3.99473 7.0059 4.11039 6.89024 4.11039 6.74758Z"
                    fill="white"
                  />
                </g>
              </g>
            </g>
            <defs>
              <clipPath id="clip0_2529_9765">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip1_2529_9765">
                <rect
                  width="30"
                  height="5.04338"
                  fill="white"
                  transform="translate(0 5.28613)"
                />
              </clipPath>
              <clipPath id="clip2_2529_9765">
                <rect
                  width="5.04338"
                  height="5.04338"
                  fill="white"
                  transform="translate(0 5.28613)"
                />
              </clipPath>
            </defs>
          </svg>
          {{ data.logo.downloadLogoTitle }}
          <a
            class="tag"
            download="true"
            :href="`${data.logo.downloadLogoPng.url}?dl=logo.png`"
          >
            <UiComponentIcon type="download" />
            PNG
          </a>
          <a
            class="tag"
            download="true"
            :href="`${data.logo.downloadLogoSvg.url}?dl=logo.svg`"
          >
            <UiComponentIcon type="download" />
            SVG
          </a>
        </div>
        <AtomAnchor
          class="item link"
          :to="data.logo.visitBrandGuidelinesLink"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M10.2211 8.7859C10.2211 8.7859 10.1211 8.7859 10.0711 8.7859C9.68111 8.7459 9.34111 8.5359 9.13111 8.2059C9.06111 8.0959 8.98111 7.9959 8.89111 7.9059C8.83111 7.8359 8.75111 7.7759 8.62111 7.6959C8.29111 7.4859 8.08111 7.1359 8.04111 6.7459C8.00111 6.3359 8.14111 5.9459 8.43111 5.6559L12.7111 1.4059C12.9511 1.1759 13.3311 1.1759 13.5611 1.4059C13.7911 1.6459 13.7911 2.0259 13.5611 2.2559L9.28111 6.5059C9.28111 6.5059 9.24111 6.6059 9.24111 6.6159C9.41111 6.7659 9.60111 6.8859 9.77111 7.0659C9.91111 7.2159 10.0411 7.3759 10.1511 7.5459C10.2511 7.5759 10.3011 7.5759 10.3311 7.5359L13.6611 4.2359C13.9011 4.0059 14.2811 4.0059 14.5111 4.2359C14.7411 4.4659 14.7411 4.8559 14.5111 5.0859L11.1811 8.3859C10.9311 8.6359 10.5911 8.7759 10.2311 8.7759L10.2211 8.7859Z"
              fill="#12262B"
            />
            <path
              d="M4.45111 14.6959C3.99111 14.6959 3.54111 14.6459 3.15111 14.5459C2.14111 14.3059 1.47111 13.7959 1.26111 13.1159C1.19111 12.8859 1.26111 12.6359 1.44111 12.4859C1.49111 12.4459 1.55111 12.4059 1.61111 12.3759C2.19111 12.1359 2.54111 11.6459 2.69111 10.8959C2.83111 10.1559 3.04111 9.1059 3.65111 8.4959C5.07111 7.0859 7.16111 7.4059 8.28111 8.5059C8.89111 9.1059 9.23111 9.8959 9.23111 10.7359C9.23111 11.3959 9.03111 12.3859 8.04111 13.3859C7.14111 14.2959 5.74111 14.6859 4.46111 14.6859L4.45111 14.6959ZM2.72111 13.1059C2.90111 13.2159 3.14111 13.3159 3.42111 13.3859C4.57111 13.6559 6.27111 13.4659 7.17111 12.5559C7.72111 11.9959 8.02111 11.3759 8.01111 10.7559C8.01111 10.2359 7.80111 9.7459 7.42111 9.3759C6.71111 8.6759 5.38111 8.4659 4.48111 9.3559C4.13111 9.7059 3.96111 10.5759 3.86111 11.0959C3.69111 11.9759 3.30111 12.6559 2.72111 13.1059Z"
              fill="#12262B"
            />
          </svg>
          {{ data.logo.visitBrandGuidelinesTitle }}
        </AtomAnchor>
      </div>
    </Transition>
  </div>
</template>

<script setup>
// Imports
import { gsap } from 'gsap'

// Composables
const { state } = useConditionalPreviewMode()
const { isDesktop } = useDevice()
const { locale } = useI18n()
const localePath = useLocalePath()
const { $lottiePlayer } = useNuxtApp()

// Directives
const { vClickOutside } = useVClickOutside()

// Props
const props = defineProps({
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
})

// Async Data
const { data } = await useFetch(`/api/content/components/logo/${locale.value}`, {
  key: `component-logo-${locale.value}`,
  query: {
    preview: state.preview,
  },
})

// Refs
const $el = ref(null)
const animations = ref(['newton', 'pong', 'stair', 'stream', 'tornado'])
const contextMenuIsDisplayed = ref(false)
const currentIndex = ref(0)
const isLottieAnimationLoaded = ref(false)
const isLottieFrameworkLoaded = ref(false)
const tooltip = ref({ x: 0, y: 0 })

// Variables
let timeline = null
let timeoutId = null

// Watchers
watch(
  () => contextMenuIsDisplayed.value,
  (value) => {
    if (value) {
      setContextMenuTimeout()
    }
  },
)

// Methods
function closeContextMenu() {
  contextMenuIsDisplayed.value = false
}

function onContextMenu({ layerX, layerY }) {
  contextMenuIsDisplayed.value = !contextMenuIsDisplayed.value
  tooltip.value = {
    x: layerX,
    y: layerY,
  }
}

function onEnter($el, done) {
  if (timeline) {
    timeline.kill()
    timeline = null
  }

  timeline = gsap.timeline()
  timeline.eventCallback('onComplete', () => {
    done()
  })
  timeline.from(
    $el,
    {
      autoAlpha: 0,
      top: '+=24',
      duration: 0.8,
      ease: 'expo.out',
    },
    0,
  )
  timeline.play()
}

function onInit() {
  isLottieFrameworkLoaded.value = true
}

function onLeave($el, done) {
  timeline.eventCallback('onReverseComplete', () => {
    done()
  })
  timeline.timeScale(1.5)
  timeline.reverse()
}

function onMouseEnter() {
  if (props.theme === 'dark' || !isDesktop || !isLottieFrameworkLoaded.value) {
    return
  }

  const players = $el.value.querySelectorAll('lottie-player')
  players[currentIndex.value].play()
}

function onMouseLeave() {
  if (props.theme === 'dark' || !isDesktop || !isLottieFrameworkLoaded.value) {
    return
  }

  const players = $el.value.querySelectorAll('lottie-player')
  players[currentIndex.value].pause()
  players[currentIndex.value].seek('0%')

  currentIndex.value += 1
  currentIndex.value %= animations.value.length
}

function onMouseMove() {
  setContextMenuTimeout()
}

function setContextMenuTimeout() {
  window.clearTimeout(timeoutId)
  timeoutId = window.setTimeout(closeContextMenu, 3000)
}

// Lifecycle hooks
onBeforeUnmount(() => {
  if (timeline) {
    timeline.kill()
    timeline = null
  }

  if (timeoutId) {
    window.clearTimeout(timeoutId)
    timeoutId = null
  }
})

onMounted(() => {
  if (isDesktop) {
    $lottiePlayer.init(onInit)
  }
})
</script>

<style lang="postcss" scoped>
.logo {
  @apply relative flex items-center h-5 w-auto z-50;

  @screen xl {
    @apply h-6;
  }

  .lottie {
    @apply absolute -left-[2px] h-6 w-6 z-10 invisible;

    @screen xl {
      @apply -left-[2.5px] -top-[2.5] h-[29px] w-[29px];
    }

    &.active {
      @apply visible;
    }
  }

  .context-menu {
    @apply absolute left-0 top-0 bg-white flex flex-col min-w-[350px] border border-grey-blue-100 rounded shadow-light2 transform translate-y-full overflow-hidden z-50;

    .item {
      @apply flex items-center h-12 px-4 bg-white text-base text-winter-green-900 cursor-default transition-colors duration-600 ease-in-out;

      &:hover {
        @apply bg-grey-blue-100;
      }

      &:nth-child(1) {
        @apply border-b border-grey-blue-200;
      }

      &.link {
        @apply cursor-pointer;
      }

      .icon {
        @apply flex flex-shrink-0 mr-2 text-base leading-none;
      }

      svg {
        @apply flex flex-shrink-0 mr-2;
      }

      .tag {
        @apply flex items-start ml-auto px-[6px] py-1 bg-white border border-grey-blue-200 font-medium text-xs text-winter-green-900 leading-[1.2] rounded-[6px];

        & + .tag {
          @apply ml-1;
        }

        .icon {
          @apply mr-1 text-sm leading-none;
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  @apply opacity-100;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
</style>
