export default defineNuxtPlugin(() => {
  const loader = new OnDemand('https://js.chilipiper.com/marketing.js', { delay: 0 })

  return {
    provide: {
      chilipiper: {
        submit(options) {
          loader.load(() => {
            window.ChiliPiper.submit('livestorm', 'inbound-router', {
              ...options,
              map: true,
            })
          })
        },
      },
    },
  }
})
