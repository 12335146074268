<template>
  <CookieControl :locale="locale">
    <template
      #bar
    >
      <UiComponentWrapper class="head">
        <UiComponentImage
          v-if="backgroundHeight && backgroundWidth && theme === 'light'"
          :key="`cookie-banner-background-${backgroundWidth}x${backgroundHeight}`"
          alt="Background"
          class="background"
          :expected-size="backgroundWidth"
          :extra-params="{
            fit: 'crop',
            h: backgroundHeight,
            w: backgroundWidth,
          }"
          :height="276"
          source="https://livestorm.imgix.net/1127/1623140270-square-livestorm.png"
          use-natural-height
          use-natural-width
          :width="1360"
        />
        <AtomLogo theme="dark" />
      </UiComponentWrapper>
      <UiComponentWrapper
        class="body"
        :class="theme"
      >
        <UiComponentHeading
          as="paragraph"
          class="title"
          :level="3"
          :theme="theme"
        >
          {{ moduleOptions.localeTexts[locale].bannerTitle }}
        </UiComponentHeading>
        <UiComponentParagraph
          class="description"
          :theme="theme"
          v-html="moduleOptions.localeTexts[locale].bannerDescription"
        />
      </UiComponentWrapper>
    </template>
  </CookieControl>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
// SEO
useHead({
  titleTemplate: (title) => {
    if (title) {
      return title.length < 50
        ? `${title} | Livestorm`
        : title
    }

    return undefined
  },
})

// Composables
const { locale } = useI18n()
const { cookiesEnabledIds, isConsentGiven, isModalActive, moduleOptions } = useCookieControl()
const { $gtm, $mutiny, $segment } = useNuxtApp()
const route = useRoute()
const theme = useState('color-scheme', () => 'light')

// Ref
const backgroundHeight = ref(null)
const backgroundWidth = ref(null)
const consentHasChanged = ref(false)
const isCookieBannerVisible = ref(false)
const resizeObserver = ref(null)

// Watchers
watch(
  () => cookiesEnabledIds.value,
  (current, previous) => {
    const difference = current.filter(id => !(previous || []).includes(id))
    if (difference.length > 0 && isCookieBannerVisible.value) {
      consentHasChanged.value = true
      $gtm.consent()
      $gtm.page()
      $mutiny.load()
    }
    else {
      window.location.reload()
    }
  },
)

watch(
  () => route.path,
  () => {
    if (consentHasChanged.value) {
      window.location.reload()
      return
    }
    else {
      $gtm.page()
    }
  },
)

watch(
  () => isConsentGiven.value,
  (value) => {
    if (value) {
      releaseScroll()
    }
  },
)

watch(
  () => isModalActive.value,
  (value) => {
    if (value) {
      preventScroll()
    }
    else {
      if (isConsentGiven.value) {
        releaseScroll()
      }
    }
  },
)

// Methods
function onObserve() {
  const $cookieBanner = document.querySelector(
    '.cookieControl .cookieControl__BarContainer .head',
  )

  if ($cookieBanner) {
    const { offsetHeight, offsetWidth } = $cookieBanner
    backgroundHeight.value = offsetHeight
    backgroundWidth.value = offsetWidth
  }
}

function preventScroll() {
  if (document.body.classList.contains('decide')) {
    return
  }
  document.body.classList.add('decide')
}

function releaseScroll() {
  if (!document.body.classList.contains('decide')) {
    return
  }
  document.body.classList.remove('decide')
}

function removeCookieControl() {
  document.querySelector('.cookieControl').style.display = 'none'
  document.querySelector('.link[data-cy="show-cookies"]').style.display = 'none'
}

// Lifecycle Hooks
onBeforeUnmount(() => {
  if (resizeObserver.value) {
    resizeObserver.value.disconnect()
    resizeObserver.value = null
  }
})

onMounted(async () => {
  const detector = new Detector()
  const isDetected = await detector.detect()

  if (isDetected) {
    removeCookieControl()
  }
  else {
    if (!isConsentGiven.value) {
      preventScroll()
      isCookieBannerVisible.value = true

      nextTick(() => {
        if (document.querySelector('.cookieControl .cookieControl__BarContainer .head')) {
          resizeObserver.value = new ResizeObserver(onObserve)
          resizeObserver.value.observe(
            document.querySelector('.cookieControl .cookieControl__BarContainer .head'),
          )
        }
      })
    }
    else {
      $gtm.consent()
      $gtm.page()
      $mutiny.load()
    }

    $segment.consent()
  }
})
</script>

<style lang="postcss">
body {
  &.dark {
    .cookieControl {
      .cookieControl__Bar {
        @apply bg-white/60;

        .cookieControl__BarContainer {
          @apply bg-[#0C181D];

          > div {
            @apply bg-[#0C181D];

            .head {
              @apply bg-gradient-to-b from-winter-green-900 to-[#0C181D];
            }

            .body {
              @apply bg-[#0C181D];

              .description {
                a {
                  @apply text-winter-green-600 !important;
                }
              }
            }

            & + .cookieControl__BarButtons {
              @apply bg-[#0C181D];

              button {
                @apply bg-winter-green-700 text-white;

                &:hover {
                  @apply bg-winter-green-800 text-white;
                }

                &:nth-child(2) {
                  @apply bg-grey-blue-200 text-winter-green-900;

                  &:hover {
                    @apply bg-grey-blue-300 text-winter-green-900;
                  }
                }

                &:nth-child(3) {
                  @apply bg-transparent text-white/80;

                  &:focus {
                    @apply outline-white;
                  }

                  &:hover {
                    @apply bg-transparent text-white;
                  }
                }
              }
            }
          }
        }
      }

      .cookieControl__Modal {
        &::after {
          @apply bg-white/60;
        }

        .cookieControl__ModalContent {
          @apply bg-[#0C181D];

          .cookieControl__ModalContentInner {
            .cookieControl__ModalClose {
              &::after {
                @apply text-white !important;
              }
            }

            h3 {
              @apply text-white;
            }

            ul {
              li {
                .cookieControl__ModalInputWrapper {
                  input {
                    + button {
                      @apply bg-[#CED0D0];
                    }

                    &:checked + button {
                      @apply bg-winter-green-700;
                    }
                  }

                  button {
                    @apply bg-grey-blue-300;

                    &::before {
                      @apply bg-white;
                    }
                  }

                  .cookieControl__ModalCookieName {
                    @apply text-white;

                    span {
                      @apply text-[#CED0D0];

                      & + span {
                        @apply bg-grey-blue-200 text-white;
                      }
                    }
                  }
                }
              }
            }

            .cookieControl__ModalButtons {
              button {
                &:nth-child(1) {
                  @apply bg-winter-green-700 text-white;

                  &:hover {
                    @apply bg-winter-green-800 text-white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.light {
    .cookieControl {
      .cookieControl__Bar {
        @apply bg-white/80;

        .cookieControl__BarContainer {
          @apply bg-white;

          > div {
            .head {
              @apply bg-livestorm-blue-700;
            }

            .body {
              .description {
                a {
                  @apply text-livestorm-blue-700 !important;
                }
              }
            }

            & + .cookieControl__BarButtons {
              button {
                @apply bg-livestorm-blue-700 text-white;

                &:hover {
                  @apply bg-livestorm-blue-800 text-white;
                }

                &:nth-child(2) {
                  @apply bg-grey-blue-200 text-winter-green-900;

                  &:hover {
                    @apply bg-grey-blue-300 text-winter-green-900;
                  }
                }

                &:nth-child(3) {
                  @apply bg-transparent text-livestorm-blue-700;

                  &:focus {
                    @apply outline-livestorm-blue-800;
                  }

                  &:hover {
                    @apply bg-transparent text-livestorm-blue-800;
                  }
                }
              }
            }
          }
        }
      }

      .cookieControl__Modal {
        &::after {
          @apply bg-white/80;
        }

        .cookieControl__ModalContent {
          @apply bg-white;

          .cookieControl__ModalContentInner {
            .cookieControl__ModalClose {
              &::after {
                @apply text-winter-green-900 !important;
              }
            }

            h3 {
              @apply text-winter-green-900;
            }

            ul {
              li {
                .cookieControl__ModalInputWrapper {
                  input {
                    &:checked + button {
                      @apply bg-livestorm-blue-700;
                    }
                  }

                  button {
                    @apply bg-grey-blue-300;

                    &::before {
                      @apply bg-white;
                    }
                  }

                  .cookieControl__ModalCookieName {
                    @apply text-winter-green-900;

                    span {
                      @apply text-grey-blue-600;

                      & + span {
                        @apply bg-grey-blue-200 text-winter-green-900;
                      }
                    }
                  }
                }
              }
            }

            .cookieControl__ModalButtons {
              button {
                &:nth-child(1) {
                  @apply bg-livestorm-blue-700 text-white;

                  &:hover {
                    @apply bg-livestorm-blue-800 text-white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cookieControl {
  .cookieControl__Bar {
    @apply flex justify-center h-full w-full;

    .cookieControl__BarContainer {
      @apply flex flex-col h-full w-full m-auto p-0 rounded-none shadow-none overflow-scroll;

      @screen md {
        @apply h-auto max-w-[680px] rounded-3xl shadow-light4 overflow-hidden;
      }

      > div {
        @apply flex flex-col w-full;

        .head {
          @apply relative p-8;

          @screen md {
            @apply p-12;
          }

          .background {
            @apply absolute inset-0 h-full w-full rounded-none z-0;
          }

          .logo {
            @apply w-40 z-10;
          }
        }

        .body {
          @apply flex-col p-8 pb-0;

          @screen md {
            @apply p-12 pb-0;
          }

          .title {
            @apply font-sans;
          }

          .description {
            @apply mt-4 font-sans;

            a {
              @apply text-livestorm-blue-700 !important;
            }
          }
        }

        & + .cookieControl__BarButtons {
          @apply flex-col mt-0 p-8 pt-6;

          @screen md {
            @apply p-12 pt-8;
          }

          @screen lg {
            @apply flex-row pt-10;
          }

          button {
            @apply rounded flex items-center justify-center h-12 px-6 font-sans font-medium text-origin leading-none cursor-pointer transition duration-500 ease-in-out;

            &:nth-child(1) {
              @apply order-last mt-3;

              @screen lg {
                @apply mt-0;
              }
            }

            &:nth-child(2) {
              @apply mt-3;

              @screen md {
                @apply ml-0;
              }

              @screen lg {
                @apply mt-0 mr-3;
              }
            }

            &:nth-child(3) {
              @apply order-first mt-0 ml-0 px-0 font-bold;

              @screen lg {
                @apply mr-auto;
              }

              &::after {
                @apply ml-2 font-normal font-icon content-["\e902"] transform transition-transform duration-500 ease-in-out;
              }

              &:focus {
                @apply outline-2;
              }

              &:hover {
                &::after {
                  @apply translate-x-1;
                }
              }
            }
          }
        }
      }
    }
  }

  .cookieControl__ControlButton {
    @apply hidden;
  }

  .cookieControl__Modal {
    @apply flex justify-center h-full w-full;

    .cookieControl__ModalContent {
      @apply flex flex-col self-start h-full w-full m-auto p-0 rounded-none shadow-light4 overflow-scroll;

      @screen md {
        @apply h-fit max-w-[680px] rounded-3xl;
      }

      .cookieControl__ModalContentInner {
        @apply relative p-8;

        @screen md {
          @apply p-12;
        }

        .cookieControl__ModalClose {
          @apply absolute right-4 top-4 flex items-center justify-center h-7 w-7 p-0 bg-transparent border-none shadow-none;

          @screen md {
            @apply right-12 top-12 h-8 w-8;
          }

          &:focus {
            @apply outline-none;
          }

          &::after {
            @apply flex font-normal font-icon text-xl leading-none content-["\e95a"] transform rotate-0 transition-transform duration-500 ease-in-out !important;

            &:hover {
              @apply rotate-90 !important;
            }
          }
        }

        h3 {
          @apply my-0 font-medium text-xl leading-7;

          @screen md {
            @apply text-2xl leading-8;
          }
        }

        ul {
          &:nth-of-type(1) {
            li {
              .cookieControl__ModalInputWrapper {
                button {
                  @apply cursor-not-allowed;
                }
              }
            }
          }

          & + h3 {
            @apply mt-6;
          }

          li {
            @apply mt-4;

            .cookieControl__ModalInputWrapper {
              @apply flex flex-row items-baseline;

              button {
                @apply h-5 p-0 border-none shadow-none transition-colors duration-500 ease-in-out;

                &::before {
                  @apply left-[2px] h-4 w-4;
                }
              }

              .cookieControl__ModalCookieName {
                @apply inline-flex flex-row flex-wrap font-medium text-sm leading-6 normal-case;

                span {
                  @apply w-full h-auto font-sans font-normal text-xs leading-4 overflow-hidden;

                  & + span {
                    @apply flex self-start justify-center items-center mt-1 mr-1 h-6 w-auto px-2 py-1 font-medium text-xs leading-4 rounded-xs;
                  }
                }
              }
            }
          }
        }

        .cookieControl__ModalButtons {
          @apply mt-6;

          button {
            @apply hidden;

            &:nth-child(1) {
              @apply rounded flex items-center justify-center h-12 px-6 font-sans font-medium text-origin leading-none cursor-pointer transition duration-500 ease-in-out;

              @screen md {
                @apply self-start;
              }

              &:focus {
                @apply outline-none;
              }
            }
          }
        }
      }
    }
  }
}

.cookieControl__Bar--bottom-full-enter-active,
.cookieControl__Bar--bottom-full-leave-active {
  @apply transition-opacity duration-500 ease-in-out;
}

.cookieControl__Bar--bottom-full-enter,
.cookieControl__Bar--bottom-full-leave-to {
  @apply opacity-0;
}
</style>
