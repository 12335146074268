<template>
  <UiComponentWrapper id="app">
    <!-- Section > Layout -->
    <UiComponentWrapper class="app__layout">
      <UiComponentWrapper
        class="app__layout-header"
        :class="{ scrolled: hasScrolled }"
      >
        <UiComponentContainer :class="{ opened: navIsOpened }">
          <OrganismNav @nav:opened="onToggleNavigation" />
        </UiComponentContainer>
      </UiComponentWrapper>
      <UiComponentWrapper class="app__layout-body">
        <UiComponentWrapper class="page">
          <UiComponentWrapper class="pattern">
            <UiComponentWrapper class="gradient" />
            <UiComponentImage
              alt="Pattern"
              class="mobile"
              :expected-size="screenWidth"
              :height="1024"
              :lazyload="false"
              orientation="portrait"
              source="https://livestorm.imgix.net/1127/1627050962-pattern-3d.png"
              :width="445"
            />
            <UiComponentImage
              alt="Pattern"
              class="desktop"
              :expected-size="screenWidth"
              :height="442"
              :lazyload="false"
              source="https://livestorm.imgix.net/1127/1626960945-pattern-3d.png"
              :width="768"
            />
          </UiComponentWrapper>
          <UiComponentContainer
            class="jumbotron"
            :start="1"
            :end="1"
          >
            <UiComponentWrapper class="actions">
              <OrganismHero
                alignment="left"
                :primary-button="{
                  ...signupOrUpgradeCallToAction,
                  label: '404 section CTA',
                }"
                :secondary-button="{
                  ...requestDemoCallToAction,
                  label: '404 section CTA',
                }"
              >
                <template #heading>
                  {{ data.page.title }}
                </template>
                <template #paragraph>
                  {{ data.page.subtitle }}
                </template>
                <template #tag>
                  {{ data.page.tag }}
                </template>
              </OrganismHero>
              <UiComponentWrapper class="resources">
                <UiComponentParagraph>{{ data.page.resourcesTitle }}</UiComponentParagraph>
                <UiComponentWrapper class="list">
                  <AtomAnchor
                    v-for="{ id, title, link } in data.page.resources"
                    :key="id"
                    :to="link"
                  >
                    {{ title }}
                  </AtomAnchor>
                </UiComponentWrapper>
              </UiComponentWrapper>
            </UiComponentWrapper>
            <UiComponentWrapper class="animation">
              <UiComponentVideo
                :height="1034"
                url="https://livestorm.imgix.net/1127/1670494693-404.mp4"
                :width="1020"
              />
            </UiComponentWrapper>
          </UiComponentContainer>
        </UiComponentWrapper>
      </UiComponentWrapper>
      <UiComponentWrapper class="app__layout-footer">
        <UiComponentContainer>
          <OrganismFooter />
        </UiComponentContainer>
      </UiComponentWrapper>
    </UiComponentWrapper>

    <!-- Modal > Demo - Step 1  -->
    <Transition
      @enter="onEnter"
      @leave="onLeave"
    >
      <UiComponentModal
        v-if="forms.demo.isVisible && !forms.demo.hasBeenSubmitted"
        key="modal-demo"
        class="app__modal demo"
        @modal:close="forms.demo.isVisible = false"
      >
        <template #title>
          {{ demoForm.firstStepTitle }}
        </template>
        <template #description>
          {{ demoForm.firstStepText }}
        </template>
        <template #content>
          <UiComponentWrapper class="content">
            <UiComponentWrapper class="left">
              <UiComponentImage
                alt="Background"
                class="background"
                :expected-size="530"
                :height="2355"
                orientation="portrait"
                source="https://livestorm.imgix.net/1127/1646403148-blue-pattern.jpg"
                :width="1590"
              />
              <UiComponentHeading
                as="paragraph"
                :level="2"
              >
                Need a Livestorm demo?
              </UiComponentHeading>
              <UiComponentHeading
                as="paragraph"
                :level="3"
              >
                Need a Livestorm demo?
              </UiComponentHeading>
              <UiComponentParagraph
                class="description"
                size="s"
              >
                Leave your information to schedule a demo with a product expert.
                We will run you through the features and answer your
                questions.
              </UiComponentParagraph>
              <UiComponentWrapper class="testimonial">
                <UiComponentImage
                  alt="Logo"
                  class="logo"
                  :expected-size="18"
                  :extra-params="{ bri: 100 }"
                  :height="56"
                  orientation="portrait"
                  source="https://livestorm.imgix.net/1127/1618317822-klaviyo.png"
                  :width="244"
                />
                <UiComponentParagraph
                  class="quote"
                  size="s"
                >
                  "We were looking for a simple webinar software that was not
                  over-engineered. If you do a side-by-side feature comparison,
                  Livestorm competes very well with the other webinar
                  players."
                </UiComponentParagraph>
                <UiComponentWrapper class="author">
                  <UiComponentImage
                    alt="Author"
                    class="picture"
                    :expected-size="32"
                    :height="400"
                    source="https://livestorm.imgix.net/1127/1505223008-clement.jpg"
                    :width="400"
                  />
                  <UiComponentParagraph class="name">
                    Michel Palaref
                  </UiComponentParagraph>
                  <UiComponentParagraph class="job">
                    Account Manager
                  </UiComponentParagraph>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <UiComponentWrapper class="logos">
                <UiComponentWrapper
                  v-for="i in 2"
                  :key="i"
                  class="row"
                >
                  <UiComponentWrapper
                    v-for="j in 4"
                    :key="`${i}-${j}`"
                    class="cell"
                  >
                    <UiComponentImage
                      alt="Logo"
                      class="logo"
                      :expected-size="80"
                      :extra-params="{ bri: 100 }"
                      :height="56"
                      source="https://livestorm.imgix.net/1127/1618317822-klaviyo.png"
                      :width="244"
                    />
                  </UiComponentWrapper>
                </UiComponentWrapper>
              </UiComponentWrapper>
            </UiComponentWrapper>
            <UiComponentWrapper class="right">
              <UiComponentWrapper class="headline">
                <UiComponentImage
                  alt="Flag"
                  class="flag fr"
                  :expected-size="16"
                  :height="16"
                  orientation="portrait"
                  source="https://livestorm.imgix.net/1127/1646403152-flag-of-france.svg"
                  :width="24"
                />
                <UiComponentImage
                  alt="Flag"
                  class="flag uk"
                  :expected-size="16"
                  :height="16"
                  orientation="portrait"
                  source="https://livestorm.imgix.net/1127/1646403153-flag-of-the-united-kingdom.svg"
                  :width="24"
                />
                <UiComponentImage
                  alt="Flag"
                  class="flag us"
                  :expected-size="16"
                  :height="16"
                  orientation="portrait"
                  source="https://livestorm.imgix.net/1127/1646403153-flag-of-the-united-states.svg"
                  :width="24"
                />
                <UiComponentParagraph size="s">
                  A Webinar Solution used by the best US companies for their
                  business
                </UiComponentParagraph>
              </UiComponentWrapper>
              <UiComponentWrapper class="options">
                <UiComponentWrapper
                  class="item"
                  :class="{ selected: currentFormIndex === 0 }"
                  @click="currentFormIndex = 0"
                >
                  <UiComponentImage
                    :alt="data.componentDemoForm.demoOptionTitle"
                    class="icon"
                    :expected-size="16"
                    :height="16"
                    source="https://livestorm.imgix.net/1127/1694504711-book-a-call.svg"
                    :width="16"
                  />
                  <UiComponentWrapper class="description">
                    <UiComponentParagraph>{{ data.componentDemoForm.demoOptionTitle }}</UiComponentParagraph>
                    <UiComponentParagraph>{{ data.componentDemoForm.demoOptionText }}</UiComponentParagraph>
                  </UiComponentWrapper>
                </UiComponentWrapper>
                <UiComponentWrapper
                  class="item"
                  :class="{ selected: currentFormIndex === 1 }"
                  @click="currentFormIndex = 1"
                >
                  <UiComponentImage
                    :alt="data.componentDemoForm.watchAVideoOptionTitle"
                    class="icon"
                    :expected-size="16"
                    :height="16"
                    source="https://livestorm.imgix.net/1127/1694504712-watch-a-video.svg"
                    :width="16"
                  />
                  <UiComponentWrapper class="description">
                    <UiComponentParagraph>{{ data.componentDemoForm.watchAVideoOptionTitle }}</UiComponentParagraph>
                    <UiComponentParagraph>{{ data.componentDemoForm.watchAVideoOptionText }}</UiComponentParagraph>
                  </UiComponentWrapper>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <OrganismHubspotNativeForm
                v-if="currentFormIndex === 0"
                :form-id="demoForm.formId"
                unique-id="form-demo-modal"
                @hubspot:form:submitted="onSubmitted(demoForm, $event)"
              />
              <OrganismHubspotNativeForm
                v-else
                :form-id="watchVideoForm.formId"
                unique-id="form-watch-a-video-modal"
                @hubspot:form:submitted="onSubmitted(watchVideoForm, $event)"
              />
            </UiComponentWrapper>
          </UiComponentWrapper>
        </template>
      </UiComponentModal>
    </Transition>

    <!-- Modal > Demo - Step 2  -->
    <Transition
      @enter="onEnter"
      @leave="onLeave"
    >
      <UiComponentModal
        v-if="forms.demo.isVisible && forms.demo.hasBeenSubmitted"
        key="modal-success"
        class="app__modal success"
        @modal:close="forms.demo.isVisible = false"
      >
        <template #title>
          {{ demoForm.lastStepTitle }}
        </template>
        <template #content>
          <UiComponentWrapper class="flex-col">
            <div
              class="message"
              v-html="$md.render(demoForm.lastStepText)"
            />
            <AtomButton
              class="mt-6"
              size="l"
              :action="signupOrUpgradeCallToAction.action"
              :to="signupOrUpgradeCallToAction.to"
            >
              {{ signupOrUpgradeCallToAction.slot }}
            </AtomButton>
          </UiComponentWrapper>
        </template>
      </UiComponentModal>
    </Transition>
  </UiComponentWrapper>
</template>

<script setup>
import { gsap } from 'gsap'

// Head
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

// Composables
const { track } = useAnalyticsJS()
useChilipiper()
const { locale } = useI18n()
const { isLogged } = useIsLoggedIn()
const { $md } = useMarkdown()
const route = useRoute()
const { public: publicRuntimeConfig } = useRuntimeConfig()
const { height: screenHeight, width: screenWidth } = useScreenSize()

// Props
const props = defineProps({
  error: Object,
})

console.error(props.error.message)

// Async Data
const { data } = await useFetch(`/api/content/layouts/error/${locale.value}`, {
  key: `layout-error-${locale.value}`,
})

// States
const forms = useState('forms', () => {
  return data.value.allForms.reduce((accumulator, currentForm) => {
    return {
      ...accumulator,
      [currentForm.slug]: {
        hasBeenSubmitted: false,
        isVisible: false,
      },
    }
  }, {})
})

// Ref
const currentFormIndex = ref(0)
const hasScrolled = ref(false)
const hasTicked = ref(false)
const navIsOpened = ref(false)

// Variables
let timeline = null

// Computed Properties
const demoForm = computed(() => {
  return data.value.allForms.find(({ slug }) => slug === 'demo')
})

const requestDemoCallToAction = computed(() => {
  return {
    action: 'Demo clicked',
    slot: data.value.componentGlobal.ctaGetDemo,
    to: '#form-demo',
  }
})

const signupOrUpgradeCallToAction = computed(() => {
  return isLogged.value
    ? {
        action: 'Upgrade clicked',
        to: `https://${publicRuntimeConfig.appBaseUrl}/#/settings?page=settings&tab=billing`,
        slot: data.value.componentGlobal.ctaUpgradeNow,
      }
    : {
        action: 'Signup clicked',
        to: `https://${publicRuntimeConfig.appBaseUrl}/#/signup`,
        slot: data.value.componentGlobal.ctaGetStartedForFree,
      }
})

const watchVideoForm = computed(() => {
  return data.value.allForms.find(({ slug }) => slug === 'watch-video')
})

// Watchers
watch(
  () => forms.value,
  () => {
    toggleScrollOnBody()
  },
  { deep: true },
)

watch(
  () => route.path,
  () => {
    clearError()
  },
)

// Functions
function onEnter($el, done) {
  if (timeline) {
    timeline.kill()
    timeline = null
  }

  timeline = gsap.timeline()
  timeline.eventCallback('onComplete', () => {
    done()
  })

  timeline.from(
    $el.querySelector('.background'),
    {
      autoAlpha: 0,
      duration: 0.6,
      ease: 'expo.out',
    },
    0,
  )
  timeline.from(
    $el.querySelector('.box'),
    {
      autoAlpha: 0,
      y: 80,
      scale: 0.9,
      duration: 0.6,
      ease: 'expo.out',
    },
    0.15,
  )
  timeline.play()
}

function onLeave($el, done) {
  timeline.eventCallback('onReverseComplete', () => {
    done()
  })
  timeline.timeScale(1.5)
  timeline.reverse()
}

function onScroll() {
  if (!hasTicked.value) {
    hasTicked.value = true
    window.requestAnimationFrame(() => {
      hasTicked.value = false
      hasScrolled.value = window.scrollY > 0
    })
  }
}

async function onSubmitted(currentForm, submittedValues) {
  // Trigger event to Google Tag Manager
  if (window.dataLayer && currentForm.gtmEvent) {
    const { v4: uuidv4 } = await import('uuid')
    dataLayer.push({ event: `${currentForm.gtmEvent}.${uuidv4()}` }) // Send event to GTM
  }

  // Trigger event to Segment
  const properties = currentForm.segmentWhitelistedProperties
    ? currentForm.segmentWhitelistedProperties
      .split(',')
      .map(value => value.trim())
      .reduce((accumulator, value) => {
        return {
          ...accumulator,
          [value]: submittedValues[value] ? submittedValues[value] : undefined,
        }
      }, {})
    : submittedValues

  await track(currentForm.segmentEvent || 'Form submitted', {
    ...properties,
    category: 'Form',
    form_id: currentForm.formId,
    label: currentForm.segmentLabel || currentForm.segmentEvent || 'Form submitted',
    locale: locale.value,
    title: window.document.title,
  })

  forms.value[currentForm.slug].hasBeenSubmitted = true

  if (currentForm.redirectUrl) {
    window.location = `${publicRuntimeConfig.baseUrl}${currentForm.redirectUrl}`
  }
}

function onToggleNavigation(event) {
  navIsOpened.value = event
}

function toggleScrollOnBody() {
  if (
    Object.values(forms.value).some(({ isVisible }) => isVisible)
    || navIsOpened.value
  ) {
    document.body.classList.add('no-scroll')
  }
  else {
    document.body.classList.remove('no-scroll')
  }
}

// Lifecycle Hooks
onBeforeUnmount(() => {
  if (timeline) {
    timeline.kill()
    timeline = null
  }

  window.removeEventListener('scroll', onScroll)
})

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})
</script>

<style lang="postcss" scoped>
#app {
  @apply flex-col items-center;

  .app__banner {
    &.event {
      @apply hidden;

      @screen md {
        @apply flex;
      }

      :deep(.highlighted) {
        @apply inline-flex items-center;

        &:hover {
          i {
            @apply translate-x-2 !important;
          }
        }

        i {
          @apply ml-2 transform translate-x-0 transition-transform duration-300 ease-in-out !important;
        }
      }
    }
  }

  .app__layout {
    @apply flex-col items-center;

    .app__layout-header {
      @apply sticky top-0 flex-row justify-center w-full z-40 transform transition-all duration-300 ease-in-out;

      &.hide {
        @apply -translate-y-full;
      }

      &.scrolled {
        @apply bg-white border-b border-grey-blue-200;
      }

      .container {
        &.opened {
          @apply bg-white;
        }
      }
    }

    .app__layout-body {
      @apply flex-col items-center;

      .page {
        @apply relative flex-col items-center;

        .pattern {
          @apply absolute left-0 -top-14 flex justify-end w-full h-full max-h-full max-w-[100vw] overflow-hidden rounded-none -z-10;

          @screen xl {
            @apply -top-[88px]
          }

          .mobile {
            @apply w-full h-full rounded-none;

            @screen lg {
              @apply hidden;
            }
          }

          .desktop {
            @apply hidden w-full h-full rounded-none;

            @screen lg {
              @apply flex;
            }
          }

          .gradient {
            @apply absolute w-full h-full z-50;
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 66%,
              rgba(255, 255, 255, 1) 100%
            );
          }
        }

        .jumbotron {
          @apply pt-8 flex-col items-center;

          @screen md {
            @apply pt-16;
          }

          @screen lg {
            @apply pt-20 flex-row flex-wrap justify-between;
          }

          @screen xxxl {
            @apply pt-26;
          }

          .actions {
            @apply flex-col;

            @screen lg {
              width: calc(510 / 1060 * 100%);
            }

            .hero {
              :deep(.tag) {
                @apply bg-blizzard-100 text-blizzard-600;
              }
            }

            .resources {
              @apply flex-col items-center mt-14;

              @screen md {
                @apply mt-20;
              }

              @screen lg {
                @apply mt-26 items-start;
              }

              p {
                @apply text-grey-blue-600 text-lg leading-7;
              }

              .list {
                @apply flex-wrap justify-center w-auto;

                a {
                  @apply mt-2 text-livestorm-blue-700 text-sm leading-6;

                  &:last-child {
                    &::after {
                      @apply content-none;
                    }
                  }

                  &::after {
                    @apply inline-flex content-['•'] mx-2 font-bold text-[#C0C0C5];
                  }
                }
              }
            }
          }

          .animation {
            @apply mt-8 bg-white rounded-2xl;

            @screen md {
              @apply mt-14 max-w-[510px] rounded-[24px];
            }

            @screen lg {
              @apply mt-0;
            }
          }
        }
      }
    }

    .app__layout-footer {
      @apply flex-col items-center;

      .container {
        @apply flex-col;
      }
    }
  }

  :deep(.app__modal) {
    &.demo  {
      .box {
        .body {
          .content {
            .left {
              @apply hidden;
            }

            .right {
              @apply flex-col;

              .headline {
                @apply hidden;
              }

              .options {
                @apply flex-col;

                @screen md {
                  @apply flex-row flex-wrap;
                }

                & + .native-form {
                  @apply mt-6;
                }

                .item {
                  @apply p-4 bg-white border border-grey-blue-300 cursor-pointer rounded;

                  @screen md {
                    width: calc((100% - 32px) / 2);
                  }

                  &.selected {
                    @apply bg-livestorm-blue-100 border-livestorm-blue-600 cursor-default;
                  }

                  &:last-child {
                    @apply mt-4;

                    @screen md {
                      @apply mt-0 ml-8;
                    }
                  }

                  .icon {
                    @apply flex-shrink-0 mt-1 rounded-none;
                  }

                  .description {
                    @apply flex-col ml-2;

                    p {
                      @apply font-medium text-winter-green-900 text-base leading-6;

                      & + p {
                        @apply mt-[2px] font-normal text-grey-blue-600 text-xs leading-[18px];
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.experience {
      &.with-banner {
        .box {
          > img {
            @screen xl {
              top: 24px;
              right: 28px;
            }
          }

          .body {
            .content {
              .right {
                .headline {
                  @screen xl {
                    @apply flex;
                  }
                }
              }
            }
          }
        }
      }

      &.with-small-title {
        .box {
          .body {
            .content {
              .left {
                .h2 {
                  @apply hidden;
                }

                .h3 {
                  @apply flex;
                }
              }
            }
          }
        }
      }

      &.with-fr-flag {
        .box {
          .body {
            .content {
              .right {
                .headline {
                  .flag {
                    &.fr {
                      @apply flex;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.with-uk-flag {
        .box {
          .body {
            .content {
              .right {
                .headline {
                  .flag {
                    &.uk {
                      @apply flex;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.with-us-flag {
        .box {
          .body {
            .content {
              .right {
                .headline {
                  .flag {
                    &.us {
                      @apply flex;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .box {
        @screen xl {
          @apply p-0 overflow-hidden;
          max-width: 1060px !important;
        }

        .head {
          @screen xl {
            @apply hidden;
          }
        }

        .body {
          @screen xl {
            @apply mt-0;
          }

          .content {
            @screen xl {
              @apply flex-row;
            }

            .left,
            .right {
              @screen xl {
                @apply flex-col w-1/2;
              }
            }

            .left {
              @screen xl {
                @apply relative flex min-h-[700px] bg-livestorm-blue-700 text-white p-10;
              }

              .background {
                @apply absolute inset-0 rounded-none z-0;
              }

              .h2,
              .h3 {
                @apply text-white z-10;
              }

              .h3 {
                @apply hidden;
              }

              .description {
                @apply font-medium text-white whitespace-normal mt-2 mb-18 leading-6 z-10;
              }

              .testimonial {
                @apply flex-col w-full mt-auto p-6 border-2 border-livestorm-blue-200 rounded-4xl z-10;
                background: rgba(255, 255, 255, 0.1);

                .logo {
                  @apply rounded-none;
                }

                .quote {
                  @apply text-grey-blue-100 whitespace-normal mt-4 leading-6;
                }

                .author {
                  @apply items-center w-full mt-4;

                  .picture {
                    @apply flex-shrink-0 border border-grey-blue-100 rounded-full;
                  }

                  .info {
                    @apply flex-col ml-3;

                    .name {
                      @apply font-medium text-base text-white leading-6;
                    }

                    .job {
                      @apply text-base text-grey-blue-100 leading-6;
                    }
                  }
                }
              }

              .logos {
                @apply flex-col w-full mt-12;

                .row {
                  @apply w-full justify-between mt-4;

                  &:first-child {
                    @apply mt-0;
                  }

                  .cell {
                    @apply justify-start items-center w-auto;

                    .logo {
                      @apply rounded-none;
                    }
                  }
                }
              }
            }

            .right {
              .headline {
                @screen xl {
                  @apply hidden justify-center w-full pl-10 pr-12 py-5 bg-grey-blue-200;
                }

                .flag {
                  @apply hidden flex-shrink-0 rounded-none mt-1 mr-2;
                }

                p {
                  @apply ml-2 font-medium text-green-900 whitespace-normal leading-6;
                }
              }

              .native-form,
              iframe {
                @screen xl {
                  @apply my-auto h-full p-10;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
